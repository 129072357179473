import React, { useContext, useEffect, useState } from 'react';
import { RANKING_GETTER_AFTER_INTERVIEW } from '../Constents/apis';
import { MainLogoComponent } from '../Components/Tiny';
import { AuthContext } from '../Components/Auth/AuthContext';

const Ranking = () => {

    const email = window.sessionStorage.getItem('email');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true);
                    const res = await fetch(RANKING_GETTER_AFTER_INTERVIEW, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email }),
                    });

                    if (res.ok) {
                        const data = await res.json();
                        const sorted = data?.map(item => {
                            const updatedScore = Object.keys(item.Score).sort().map(key => { return { ...item.Score?.[key], Question_Number: parseInt(key.split("Question_Number")[1]) } });
                            return { ...item, Score: updatedScore };
                        })
                        setData(sorted);
                    } else {
                        const error = await res.json();
                        throw new Error(error.error);
                    }

                }
                catch (err) {
                    setError(err.message);
                }
                finally {
                    setLoading(false);

                }
            }
        )();
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);

    const { isEmployerExist } = useContext(AuthContext);

    if (data?.length > 0) {
        return (<div className=''>
            <div className='w-full mt-6 flex flex-col items-end'>
                <h1 className=' text-xl font-bold'>{data?.[0]?.Job_Title}</h1>
                <p className=' text-sm'>{data?.[0]?.Hr_Email}</p>
            </div>
            <div className='mt-20 flex flex-col lg:flex-row gap-4'>
                <div className='flex-1 border-[2px] border-gray-200 rounded-md'>
                    <table className='min-w-full table-auto'>
                        <thead className='bg-gray-100'>
                            <tr>
                                <th className='p-2 text-left w-40 font-semibold'>Name</th>
                                <th className='p-2 text-left w-40 font-semibold'>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr
                                        key={index}
                                        className={`hover:bg-gray-100 ${activeIndex === index && 'bg-gray-100'} cursor-pointer`}
                                        onClick={() => setActiveIndex(index)}
                                    >
                                        <td className='p-2'>{item.Applicant_Name}</td>
                                        <td className='p-2'>{item.Applicant_Email}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className='flex-1'>
                    <table className='min-w-full table-auto'>
                        <thead className='bg-gray-100'>
                            <tr>
                                <th className='p-2 text-left w-12 font-semibold'>QN</th>
                                <th className='p-2 text-left w-32 font-semibold'>Score</th>
                                <th className='p-2 text-left w-30 font-semibold'>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.[activeIndex]?.Score?.map((item, index) => (
                                    <tr key={index} className='hover:bg-gray-100'>
                                        <td className='p-2'>{item?.Question_Number}</td>
                                        <td className='p-2'>{item?.Applicant_Score}</td>
                                        <td className='p-2'>{item?.Reason}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>)
    }

    if (loading) {
        return (<div className=' w-full flex justify-center'>
            <h1 className=' text-xl text-primary-800 font-semibold'>loading...</h1>
        </div>)
    }

    if (error) {
        return (<div className=' w-full flex justify-center'>
            <h1 className=' text-xl font-semibold'>{error}</h1>
        </div>)
    }

}

export default Ranking



// return (<>
//     {/* {data?.length > 0 && (<div className=''>
//         <div className='w-full mt-6 flex flex-col items-end'>
//             <h1 className=' text-xl font-bold'>{data?.[0]?.Job_Title}</h1>
//             <p className=' text-sm'>{data?.[0]?.Hr_Email}</p>
//         </div>
//         <div className='mt-20 flex flex-col lg:flex-row gap-4'>
//             <div className='flex-1 border-[2px] border-gray-200 rounded-md'>
//                 <table className='min-w-full table-auto'>
//                     <thead className='bg-gray-100'>
//                         <tr>
//                             <th className='p-2 text-left w-40 font-semibold'>Name</th>
//                             <th className='p-2 text-left w-40 font-semibold'>Email</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {
//                             data?.map((item, index) => (
//                                 <tr
//                                     key={index}
//                                     className={`hover:bg-gray-100 ${activeIndex === index && 'bg-gray-100'} cursor-pointer`}
//                                     onClick={() => setActiveIndex(index)}
//                                 >
//                                     <td className='p-2'>{item.Applicant_Name}</td>
//                                     <td className='p-2'>{item.Applicant_Email}</td>
//                                 </tr>
//                             ))
//                         }
//                     </tbody>
//                 </table>
//             </div>
//             <div className='flex-1'>
//                 <table className='min-w-full table-auto'>
//                     <thead className='bg-gray-100'>
//                         <tr>
//                             <th className='p-2 text-left w-12 font-semibold'>QN</th>
//                             <th className='p-2 text-left w-32 font-semibold'>Score</th>
//                             <th className='p-2 text-left w-30 font-semibold'>Reason</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {
//                             data?.[activeIndex]?.Score?.map((item, index) => (
//                                 <tr key={index} className='hover:bg-gray-100'>
//                                     <td className='p-2'>{item?.Question_Number}</td>
//                                     <td className='p-2'>{item?.Applicant_Score}</td>
//                                     <td className='p-2'>{item?.Reason}</td>
//                                 </tr>
//                             ))
//                         }
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     </div>)} */}
//     {/* {
//         loading && (<div className=' w-full flex justify-center'>
//             <h1 className=' text-xl text-primary-800 font-semibold'>loading...</h1>
//         </div>)
//     } */}
//     {/* {
//         error && (<div className=' w-full flex justify-center'>
//             <h1 className=' text-xl font-semibold'>{error}</h1>
//         </div>)
//     } */}
// </>




// )