import React, { useContext } from 'react'
import { RankRequirementsContext } from '../Contexts/RankRequirements';

const ResumesUploadingButton = () => {
    const { dispatch } = useContext(RankRequirementsContext);
    return (
        <form action=""
            className=' my-12'
            onChange={(e) => {
                dispatch({ type: 'RESUMESUPDATE', payload: e.target.files });
            }}>
            <input type="file" multiple="multiple" id='talentUploadButtonLabel' className=' hidden' accept=".zip, .rar" />
            <label htmlFor='talentUploadButtonLabel' className=' px-12 py-6 rounded-full bg-primary-800 hover:bg-primary-700 text-2xl font-semibold text-white cursor-pointer'>
                Select Resumes
            </label>
        </form>
    )
}

export default ResumesUploadingButton;