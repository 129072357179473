import React from 'react'

const Input = ({ type, heading, placeholder, value, setValue }) => {
    return (
        <div>
            {heading && (<p className='mt-6 mb-2 font-semibold'>Company Name</p>)}
            <input
                className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                type={type ?? 'text'}
                placeholder={placeholder ?? ''}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    )
}

export default Input;