import React from 'react'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
    const Navigate = useNavigate();
    return (
        <div className=''>
            <div className=' w-full flex flex-col lg:flex-row gap-8 items-center justify-center min-h-[50vh]'>
                <button className=' px-6 py-4 rounded-2xl text-white bg-primary-800 text-xl' 
                onClick={() => Navigate('/employer')}
                >
                    Are you Employer?
                </button>
                <button className=' px-6 py-4 rounded-2xl text-white bg-primary-800 text-xl'
                // onClick={() => Navigate('/applicant')}
                >
                    Applicant
                </button>
                <button className=' px-6 py-4 rounded-2xl text-white bg-primary-800 text-xl'
                onClick={() => Navigate('/ranking')}
                >
                    Ranking
                </button>
            </div>

        </div>
    )
}

export default HomePage;