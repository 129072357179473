import React, { useContext, useState } from 'react';
import { HR_LOGOUT } from '../Constents/apis';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Components/Auth/AuthContext';

const useLogout = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const Navigate = useNavigate();
    const {setIsEmployerExist} = useContext(AuthContext);

    const logOutFunction = async () => {
        try {
            setLoading(true);
            const res = await fetch(HR_LOGOUT, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${window.sessionStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
            });
            if (res.ok) {
                const data = await res.json();
                window.sessionStorage.removeItem("accessToken");
                window.sessionStorage.removeItem("addedTime");
                window.sessionStorage.removeItem("email");
                setIsEmployerExist(false);
                Navigate("/auth");
            }
            else {
                throw new Error("Somthing went wrong while logout");
            }
        }
        catch (err) {
            setError(err);
        }
        finally {
            setLoading(false);
        }
    }

    return [loading, error, logOutFunction];
}

export default useLogout;