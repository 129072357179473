import React, { useState } from 'react'

const Upload = ({ form }) => {

  return (
    // <div className=' py-12'>

      <form action=""
        onChange={(e) => {
          const files = e.target.files;
          for (let file of files) {
            form.current.append("files", file);
          }
        }}>
        <input type="file" multiple="multiple" id='talentUploadButtonLabel' className=' hidden' accept=".zip, .rar" />
        <label htmlFor='talentUploadButtonLabel' className=' px-12 py-6 rounded-full bg-primary-800 hover:bg-primary-700 text-2xl font-semibold text-white cursor-pointer'>
          Select Resume
        </label>
      </form>
    // </div>
  )
}
export default Upload;