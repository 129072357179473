import React, { useState, useContext, useEffect } from 'react';
import { JobsType } from '../Constents/text';
import { RankRequirementsContext } from '../Contexts/RankRequirements';


const Second = ({ department }) => {
    const { dispatch } = useContext(RankRequirementsContext);
    const type = JobsType?.filter(item => item.department === department)[0];

    useEffect(()=>{
        dispatch({ type: 'JOBTITLEUPDATE', payload: type?.jobs?.[0]})
    }, [])
    return (<>
        <h6 className=' mb-2 ml-2 text-sm'>*Select job title</h6>
        <div className=' w-full p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
            <select className=' outline-none border-none'
                onChange={(e) => dispatch({ type: 'JOBTITLEUPDATE', payload: e.target.value })}
            >
                {type?.jobs?.map((item, index) => (
                    <option
                        key={index}
                        value={item}
                    >
                        {item}
                    </option>))
                }
            </select>
        </div>
    </>)
}


const JobTitle = () => {
    const [department, setDepartment] = useState(JobsType?.[0]?.department);    
    return (
        <div className=' max-w-[24rem] mt-4'>

            <h6 className=' mb-2 ml-2 text-sm'>*Select job industry</h6>
            <div className=' w-full p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
                <select
                    onChange={(e) => setDepartment(e.target.value)}
                    className=' outline-none border-none accent-primary-600'
                >
                    {JobsType.map((item, index) => (
                        <option
                            key={index}
                            value={item.department}
                            className='hover:bg-primary-200'
                        >
                            {item.department}
                        </option>))
                    }
                </select>
            </div>
            {department && (<Second department={department} />)}
        </div>
    )
}

export default JobTitle;