import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { hrRegisterSchema } from '../../Constents/schema';
import { HR_REGISTRATION } from '../../Constents/apis';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';

const Register = ({ setIsFirstTimeUser }) => {
    const Navigate = useNavigate();
    const { setIsEmployerExist } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const initialEnteries = {
        hrName: '',
        companyName: '',
        hrEmail: '',
        password: '',
        confirmPassword: '',
    }

    // const hrRegisterFunction = async (params = {}) => {

    //     let err = false;
    //     let success = false;
    //     try {
    //         setLoading(true);
    //         const res = await fetch(HR_REGISTRATION, {
    //             method: "POST",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 hrName: params.hrName,
    //                 company_name: params.companyName,
    //                 email: params.hrEmail,
    //                 password: params.password,
    //             })
    //         });

    //         if (res.status === 201) {
    //             const data = await res.json();
    //             window.sessionStorage.setItem("accessToken", data?.token);
    //             window.sessionStorage.setItem("addedTime", Date.now());
    //             setIsEmployerExists(true);
    //             window.location.assign(window.location.host);
    //             Navigate('/')

    //         }
    //         else {
    //             throw new Error("Somthing went wrong while registration");
    //         }
    //     }
    //     catch (err) {
    //         setError(err);
    //         err = true;
    //     }
    //     finally {
    //         setLoading(false);
    //     }

    //     return success ? true : false;
    // }

    const hrRegisterFunction = async (params = {}) => {
        try {
            setLoading(true);
            const res = await fetch(HR_REGISTRATION, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    hrName: params.hrName,
                    company_name: params.companyName,
                    email: params.hrEmail,
                    password: params.password,
                })
            });

            if (res.ok) {
                const data = await res.json();
                window.sessionStorage.setItem("accessToken", data?.token);
                window.sessionStorage.setItem("addedTime", Date.now());
                window.sessionStorage.setItem("email", params.hrEmail,);
                setIsEmployerExist(true);
                Navigate('/');
            }
            else {
                const err = await res.json();
                throw new Error(err.error);
            }
        } catch (err) {
            setError(err.message);  // Get the error message here
        } finally {
            setLoading(false);
        }
    }


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            validationSchema: hrRegisterSchema,
            initialValues: initialEnteries,
            onSubmit: (values, action) => {
                hrRegisterFunction(values).then(res => {
                    res && Navigate('/');
                })
                // acCreater(values).then(action.resetForm());
            },
        });


    return (
        <form
            onSubmit={handleSubmit}
            className='p-4 rounded-xl min-w-80 md:min-w-96'
            style={{ "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>*Email </p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.hrEmail && touched.hrEmail ? errors.hrEmail : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='text'
                    id='hrEmail'
                    name='hrEmail'
                    placeholder='Enter your email here....'
                    value={values.hrEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>Company Name </p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.companyName && touched.companyName ? errors.companyName : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='text'
                    id='companyName'
                    name='companyName'
                    placeholder='Enter your company name'
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>Name</p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.hrName && touched.hrName ? errors.hrName : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='text'
                    id='hrName'
                    name='hrName'
                    placeholder='Enter your name'
                    value={values.hrName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>Password</p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.password && touched.password ? errors.password : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='password'
                    id='password'
                    name='password'
                    placeholder='create your password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>Confirm Password</p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='password'
                    placeholder='create your password'
                    name="confirmPassword"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <p className='mb-2 text-[#ff0000]'> {error}</p>
            <button
                type='submit'
                className=' px-4 py-2 rounded-lg text-white bg-primary-800 hover:bg-primary-500 hover:cursor-pointer'
            >
                Register
            </button>
            <p className='mt-4 text-xs font-semibold'>All entries are required</p>
            <p className='mt-8 text-xs font-semibold'>Already have an account
                <span
                    className='ml-1 text-primary-800 font-bold cursor-pointer hover:underlinee'
                    onClick={() => setIsFirstTimeUser(false)}
                >login </span>
                here
            </p>
        </form>
    )
}

export default Register;