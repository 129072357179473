import React, { useState } from 'react';
import arrow from '../Assets/arrow_up.svg';
import { Hr } from '../Constents/ConstentComp';

const transitionStyle = {
    transitionDuration: "400ms",
}



const EducationDetails = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [education, setEducations] = useState([{}]);
    return (
        <div className='flex flex-col'>
            <div className='flex w-full justify-between py-2'>
                <h2 className='text-2xl font-semibold'>Education Details</h2>
                <img
                    className={` cursor-pointer ${isOpen ? " rotate-0" : " rotate-180"}`}
                    style={transitionStyle}
                    src={arrow}
                    onClick={() => setIsOpen(pre => !pre)}
                />
            </div>
            <div
                className={`gap-4 transition-all ease-in-out rounded-lg grid ${isOpen
                    ? "grid-rows-[1fr] "
                    : "grid-rows-[0fr] "
                    }`}
                style={transitionStyle}
            >
                <div className=' overflow-hidden flex flex-col gap-6'>
                    {
                        education.map((item, index) => (<div key={index} className=' px-4 py-2 rounded-lg border-[1px] border-gray-400'>
                            <div className=' flex flex-col gap-2'>
                                <label className=' font-semibold'>
                                    Highest Education Qualification
                                </label>
                                < select name="highestQualification" id="highestQualification" className=' border-[1px] border-gray-400 rounded-sm outline-none px-1 py-2 w-full' >
                                    <option value="volvo">Phd.</option>
                                    <option value="saab">Masters</option>
                                    <option value="mercedes">Bachelor</option>
                                    <option value="audi">Less than Bachelors</option>
                                </select >
                            </div>
                            <div className=' flex flex-col gap-1'>
                                <label className=' font-semibold'>
                                    Name of Deggree
                                </label>
                                <input
                                    className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                                    type='text'
                                    placeholder=''
                                />
                            </div>
                            <div className=' flex flex-col gap-1'>
                                <label className=' font-semibold'>
                                    CGPA / Percentage
                                </label>
                                <input
                                    className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                                    type='Number'
                                    max={100}
                                    min={0}
                                    placeholder=''
                                />
                            </div>
                            <div className=' flex flex-col gap-1'>
                                <label className=' font-semibold'>
                                    Passing year
                                </label>
                                <input
                                    className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                                    type='Number'
                                    max={2030}
                                    min={1990}
                                    placeholder=''
                                />
                            </div>
                        </div>))
                    }
                    <div className=' flex justify-end'>
                        <button
                            onClick={() => setEducations(pre => [...pre, {}])}
                            className=' px-4 py-2 hover:bg-primary-500 border-[1px] border-black rounded-lg'>Add more Eductions</button>
                    </div>
                    <div className=' h-1'></div>
                </div>
            </div>
            <Hr />
        </div>
    )
}

export default EducationDetails