import React, { useState } from 'react';
import Register from './Register';
import LogIn from './LogIn';
import { validateAuth } from '../../Constents/functions';

const Auth = () => {
    const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);

    if (validateAuth()) {
        window.history.back();
        return '';
    }
    return (
        <div className=' w-full flex items-center justify-center'>
            {isFirstTimeUser ? <Register setIsFirstTimeUser={setIsFirstTimeUser} /> : <LogIn setIsFirstTimeUser={setIsFirstTimeUser} />}
        </div>
    )
}

export default Auth;