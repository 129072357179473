export const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"];
export const years = [
    2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013,
    2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001,
    2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989,
    1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981];


export const JobsType = [
    {
        department: "Banking, Financial Services, and Insurance",
        jobs: [
            "Finance", "Accounting & Taxation", "Banking Operations", "Lending", "Life Insurance", "General Insurance", "Treasury & Forex", "Trading, Asset & Wealth Management", "Investment Banking, Private Equity & VC", "Audit & Control", "Finance & Accounting - Other", "Risk Management & Compliance - Other", "Treasury", "BFSI, Investments & Trading - Other"
        ],
    },
    {
        department: "Business Process Management",
        jobs: [
            "Retail & B2C Sales", "BD / Pre Sales", "Enterprise & B2B Sales", "Sales Support & Operations", "Marketing", "Digital Marketing", "Advertising & Creative", "Market Research & Insights", "Category Management & Operations", "Recruitment Marketing & Branding", "Voice / Blended", "Customer Success", "Non Voice", "Customer Success, Service & Operations - Other", "Back Office", "After Sales Service & Repair", "Operations Support", "Service Delivery", "Operations / Strategy", "Front Office & Guest Services", "Retail Store Operations", "Telecom", "Events & Banquet", "eCommerce Operations", "F&B Service", "Security Officer", "Recruitment & Talent Acquisition", "HR Operations", "Compensation & Benefits", "Payroll & Transactions", "Employee Relations", "HR Business Advisory", "Human Resources - Other", "Procurement & Purchase", "Procurement & Supply Chain - Other", "SCM & Logistics", "Stores & Material Management", "Import & Export", "Other Program / Project Management", "Strategic Management", "Operations / Strategy", "Administration", "Administration & Staff", "Facility Management", "Housekeeping & Laundry", "Quality Assurance and Testing", "Quality Assurance - Other", "Business Process Quality", "Risk Management & Compliance - Other", "Security / Fraud", "Security Officer", "Treasury", "Top Management", "Strategic Management", "Corporate Training", "Strategic & Top Management - Other", "Security / Fraud", "Security Officer", "Security Services - Other"
        ],
    },
    {
        department: "Consumer, Retail & Hospitality",
        jobs: [
            "Corporate Communication", "Corporate Affairs", "Marketing and Communication - Other", "Kitchen / F&B Production", "F&B Service", "Food, Beverage & Hospitality - Other", "Front Office & Guest Services", "Merchandising & Planning", "Merchandising, Retail & eCommerce - Other", "Category Management & Operations", "Retail Store Operations", "Procurement & Supply Chain - Other"
        ],
    },
    {
        department: "Education",
        jobs: [
            "Subject / Specialization Teacher", "Teaching & Training - Other", "Corporate Training", "University Level Educator", "Life Skills / ECA Teacher", "Language Teacher", "Preschool & Primary Education"
        ],
    },
    {
        department: "Healthcare & Life Sciences",
        jobs: ["Doctor", "Nursing", "Other Hospital Staff", "Healthcare & Life Sciences - Other", "Health Informatics", "Pharmaceutical & Biotechnology", "Imaging & Diagnostics", "Occupational Health & Safety", "Community Health & Safety", "Environment Health & Safety - Other", "Health & Fitness", "Sports Science & Medicine", "Downstream", "Upstream"
        ],
    },
    {
        department: "IT Services",
        jobs: ["Software Development", "Quality Assurance and Testing", "DBA / Data warehousing", "IT Consulting", "IT Network", "IT Infrastructure Services", "IT Support", "IT Security", "DevOps", "Data Science & Machine Learning", "IT & Information Security - Other", "Technology / IT", "Product Management - Technology", "UI / UX", "Engineering", "Operations, Maintenance & Support", "Business Intelligence & Analytics", "Data Science & Machine Learning", "Data Science & Analytics - Other", "IT Consulting", "IT Network", "IT Infrastructure Services", "IT Support", "IT Security", "IT & Information Security - Other", "Hardware and Networks - Other", "Security / Fraud", "Hardware", "Engineering & Manufacturing", "Hardware and Networks - Other"
        ],
    },
    {
        department: "Manufacturing & Production",
        jobs: [
            "Engineering & Manufacturing", "Production & Manufacturing", "Production & Manufacturing - Other", "Construction Engineering", "Construction / Manufacturing", "Construction & Site Engineering"
        ],
    },
    {
        department: "Media, Entertainment & Telecom",
        jobs: ["Content Management", "Editing", "Editing", "Journalism", "Content, Editorial & Journalism - Other", "Media Production & Entertainment - Other", "Direction", "Animation / Effects", "Artists", "Sound / Light / Technical Support"
        ],
    },
    {
        department: "Professional Services",
        jobs: ["IT Consulting", "Management Consulting", "Other Consulting", "Other Program / Project Management", "Assessment / Advisory", "Legal Operations", "Risk Management & Compliance - Other", "Legal & Regulatory - Other"],
    },
    {
        department: "Technology",
        jobs: [
            "Architecture & Interior Design", "Other Design", "Animation / Effects", "Fashion & Accessories", "Artists", "UI / UX", "Research & Development - Other", "Product Management - Technology", "Product Management - Other"
        ],
    },
    {
        department: "Other",
        jobs: ["Strategic & Top Management - Other", "Operations / Strategy", "Risk Management & Compliance - Other", "Other Design", "Legal & Regulatory - Other", "Content, Editorial & Journalism - Other", "Media Production & Entertainment - Other", "CSR & Sustainability", "Other Consulting", "Surveying", "Direction", "Flight & Airport Operations", "Security Services - Other", "Sports, Fitness & Personal Care - Other", "Social & Public Service", "Shipping & Maritime - Other", "Shipping Deck", "Energy & Mining - Other", "Construction / Manufacturing", "Shipping Engineering & Technical", "Shipping & Maritime", "Environment Health & Safety - Other", "Mining", "Power Generation", "Power Supply and Distribution", "Corporate Training", "Life Skills / ECA Teacher", "University Level Educator", "Preschool & Primary Education", "Top Management", "Kitchen / F&B Production", "Beauty & Personal Care", "Food, Beverage & Hospitality - Other", "Environment Health & Safety - Other", "Occupational Health & Safety", "Community Health & Safety", "Surveying", "Sports Staff and Management", "Health & Fitness", "Beauty & Personal Care", "Sports, Fitness & Personal Care - Other", "Pilot", "Airline Services", "Flight & Airport Operations", "Aviation Engineering", "Aviation & Aerospace - Other", "CSR & Sustainability", "Social & Public Service", "Power Generation", "Power Supply and Distribution", "Energy & Mining - Other", "Port & Maritime Operations", "Shipping Deck", "Shipping Engineering & Technical", "Shipping & Maritime - Other"
        ],
    },
]
