import React, { useContext } from 'react'

import { RankRequirementsContext } from '../Contexts/RankRequirements';

const JdSlectButton = () => {
    const { state, dispatch } = useContext(RankRequirementsContext);
    return (
        <form action=""
            onChange={(e) => { dispatch({ type: 'JDUPDATE', payload: e.target.files[0] }) }}>

            <input type="file" multiple="multiple" id='talent_JD_upload_label' className=' hidden' />
            <label htmlFor='talent_JD_upload_label' className=' px-6 py-2 rounded-full bg-primary-800 hover:bg-primary-700 text-lg text-white cursor-pointer'>
                Select JD
            </label>
        </form>
    )
}

export default JdSlectButton;



// set_JD_Name(e.target.files[0]?.name); form.current.append("jd", e.target.files[0])