import React, { useContext, useState } from 'react'
import Heading_with_title from './Heading_with_title';
import Question_Add from '../Assets/Components/Question_Add';
import Question_Remove from '../Assets/Components/Question_Remove';
import { RankRequirementsContext } from '../Contexts/RankRequirements';

const Specific_Questions = () => {

    const { state, dispatch } = useContext(RankRequirementsContext);
    const { questions } = state;
    return (
        <div>
            <Heading_with_title text={"Specific Questions"} title={"Add specific questions that you want to ask all the candidates"} />
            <div className=' my-4 flex flex-col gap-4'>
                {
                    questions.map((item, index) => (
                        <div key={index} className=' p-3 w-full rounded-lg border-2 border-gray-200 flex flex-col gap-4'>
                            <h2 className=' text-lg font-semibold -mb-1'>Question: {index + 1}</h2>
                            <textarea
                                value={item[0]}
                                placeholder='Your question here....'
                                onChange={(e) => dispatch({ type: 'QUESTION', index, payload: [e.target.value, item[1]] })}
                                rows={3}
                                className=' p-2 w-full bg-primary-light-200 rounded-lg border-none outline-none resize-none'
                            />
                            <textarea
                                value={item[1]}
                                placeholder='Enter your expected answer here....'
                                onChange={(e) => dispatch({ type: 'QUESTION', index, payload: [item[0], e.target.value] })}
                                rows={4}
                                className=' p-2 w-full bg-primary-light-200 rounded-lg border-none outline-none resize-none'
                            />
                        </div>
                    ))
                }
            </div>
            <div className=' flex w-full justify-end gap-2'>
                {questions?.length < 3 && (<div
                    title='Add more question'
                    className=' cursor-pointer h-6'
                    alt="add Question"
                    onClick={() =>dispatch({type:'ADDQUESTION'})} >
                    <Question_Add />
                </div>)}
                {questions?.length > 0 && (<div
                    className=' cursor-pointer h-6'
                    title='remove last question'
                    alt="add Question"
                    onClick={() =>dispatch({type:'REMOVEQUESTION'})}
                >
                    <Question_Remove />
                </div>)}
            </div>
        </div>
    )
}

export default Specific_Questions;