// import React from 'react'
// import Input_Range from './Input_Range';
// import { useState } from 'react';

// const Ranges_Section = ({ state }) => {

//     const [ranges, setRanges] = state;
//     const [totleNumbers, setTotleNumbers] = useState(new Array(Object.keys(ranges).length).fill(0));

//     return (
//         <div className=' flex flex-col gap-2'>
//             {Object.keys(ranges).map((item, index) => (
//                 <Input_Range
//                     index={index}
//                     text={item}
//                     key={index}
//                     ranges={ranges}
//                     setter={setRanges}
//                     totleNumbers={totleNumbers}
//                     setTotleNumbers={setTotleNumbers}
//                 />))}
//         </div>
//     );
// }

// export default Ranges_Section;


import React, { useContext } from 'react'
import Input_Range from './Input_Range';
import { useState } from 'react';
import { RankRequirementsContext } from '../Contexts/RankRequirements';

const Ranges_Section = () => {
    const { state, dispatch } = useContext(RankRequirementsContext);
    const { ranges } = state;
    return (
        <div className=' flex flex-col gap-2'>
            {Object.keys(ranges).map((rangeName, index) => (
                <div
                    key={index}
                    className=" flex gap-4"
                >
                    <p className=' w-28 text-left'>{rangeName}</p>
                    <input
                        type='range'
                        onChange={(e) => dispatch({ type: 'RANGEUPDATE', payload: { name: rangeName, value: e.target.value } })}
                        value={state.ranges?.[rangeName]}
                        className=' accent-primary-700'
                    />
                    <span>{ranges[rangeName]}</span>
                </div>))}
        </div>
    );
}

export default Ranges_Section;