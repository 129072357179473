import React, { useState } from 'react';
import arrow from '../Assets/arrow_up.svg';
import { Hr } from '../Constents/ConstentComp';

const transitionStyle = {
    transitionDuration: "400ms",
}

const PersonalDetails = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='flex flex-col'>
            <div className='flex w-full justify-between py-2'>
                <h2 className='text-2xl font-semibold'>Personal Details</h2>
                <img
                    className={` cursor-pointer ${isOpen ? " rotate-0" : " rotate-180"}`}
                    style={transitionStyle}
                    src={arrow}
                    onClick={() => setIsOpen(pre => !pre)}
                />
            </div>
            <div
                className={`gap-4 transition-all ease-in-out rounded-lg grid ${isOpen
                    ? "grid-rows-[1fr] "
                    : "grid-rows-[0fr] "
                    }`}
                style={transitionStyle}
            >
                <div className=' overflow-hidden flex flex-col gap-4'>
                    <div className=' flex flex-col gap-1'>
                        <label className=' font-semibold'>
                            Full Name
                        </label>
                        <input
                            className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                            type='text'
                            placeholder='Enter your Full Name'
                        />
                    </div>
                    <div className=' flex flex-col gap-1'>
                        <label className=' font-semibold'>
                            Email
                        </label>
                        <input
                            className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                            type='text'
                            placeholder='Enter your Email here'
                        />
                    </div>
                    <div className=' flex flex-col gap-1'>
                        <label className=' font-semibold'>
                            Phone No.
                        </label>
                        <input
                            className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                            type='number'
                            placeholder='Enter your Phone No.'
                        />
                    </div>
                    <div className=' flex flex-col gap-1 mb-4'>
                        <p className=' font-semibold'>
                            Gender
                        </p>
                        <div className=' flex gap-4'>
                            <div className=' flex items-center gap-1 justify-center'>
                                <input type="radio" name="gender" id="" className=' cursor-pointer' />
                                <label>Male</label>
                            </div>
                            <div className=' flex items-center gap-1 justify-center'>
                                <input type="radio" name="gender" id="" className=' cursor-pointer' />
                                <label>Female</label>
                            </div>
                            <div className=' flex items-center gap-1 justify-center'>
                                <input type="radio" name="gender" id="" className=' cursor-pointer' />
                                <label>Other</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Hr />
        </div>
    )
}

export default PersonalDetails