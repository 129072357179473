import React, { useState } from 'react';
import PersonalDetails from '../Components/PersonalDetails';
import EducationDetails from '../Components/EducationDetails';
import WorkExperience from '../Components/WorkExperience';
import Skills from '../Components/Skills';
import Certifications from '../Components/Certifications';
import cross from '../Assets/close.svg';
import { JobsType } from '../Constents/text';
import Upload from '../Components/Upload';

const Applicant = () => {

  const [selectedJobsTitle, setSelectedJobsTitle] = useState([]);
  const [department, setDepartment] = useState('');

  const valueRemover = (value, valuse) => {
    const list = valuse?.filter(item => item === value ? false : true);
    setSelectedJobsTitle(list);
  }


  return (<>

    <div className=' w-full flex gap-2'>
      <div className=' flex-1 h-full p-6 flex justify-center items-center flex-col'>
        <Upload />
        <div className=' max-w-[24rem]'>

          <h5 className=' mb-2 ml-2 font-semibold'>Select up to 3 JOB title</h5>
          <div className=' w-full p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
            <select
              onChange={(e) => setDepartment(e.target.value)}
              className=' outline-none border-none'
            >
              {JobsType.map((item, index) => (
                <option
                  key={index}
                  value={item.department}
                >
                  {item.department}
                </option>))
              }
            </select>
          </div>
          <div className=' w-full p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
            <select className=' outline-none border-none'
              onChange={(e) => setSelectedJobsTitle(pre => [...pre, e.target.value])}
            >
              {JobsType?.filter(item => item.department === department)[0]?.jobs?.map((item, index) => (
                <option
                  key={index}
                  value={item}
                >
                  {item}
                </option>))
              }
            </select>
          </div>
          <div className=' w-full flex gap-2 flex-wrap'>
            {selectedJobsTitle.slice(-3).map((item, index) => (
              <div key={index} className='px-5 py-2 flex gap-2 items-center rounded-full font-semibold bg-gray-100 w-fit'>
                <span>{item}</span>
                <img
                  className=' cursor-pointer'
                  src={cross}
                  onClick={(e) => valueRemover(item, selectedJobsTitle)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=' flex-1 p-6 pt-32 h-full overflow-x-hidden'>
        <PersonalDetails />
        <EducationDetails />
        <WorkExperience />
        <Skills />
        <Certifications />
      </div>
    </div>
  </>)
}

export default Applicant;





// <div className=' px-20 py-40 w-1/2 flex flex-col gap-4'>
//   <div>

//   </div>
// </div>