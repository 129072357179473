import { createContext, useReducer } from "react"
import JobTitle from "../Components/JobTitle";

export const RankRequirementsContext = createContext();

export const RankRequirementsProvider = ({ children }) => {

    const initial = {
        jd: '',
        jdName: '',
        resumes: [],
        ranges: {
            'Education': 0,
            'Skills': 0,
            'Experience': 0,
            'Certification': 0,
            'Project': 0,
            'Achievement': 0,
        },
        options: {
            'DISC Profile': false,
            'Situation Based': false,
        },
        questions: new Array(1).fill(['', '']),
        email: '',
        companyValues: [],
        companyName: '',
        jobsTitle: '',
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case 'EMAIL':
                return { ...state, email: action.payload }
            case 'QUESTION':
                return { ...state, questions: (() => { const pre = state.questions; pre[action.index] = action.payload; return pre; })() }
            case 'ADDQUESTION':
                return { ...state, questions: (() => { const pre = [...state.questions, ['', '']]; return pre; })() }
            case 'REMOVEQUESTION':
                return { ...state, questions: (() => { const pre = [...state.questions]; pre.pop(); return pre; })() }
            case 'COMPANYNAME':
                return { ...state, companyName: action.payload }
            case 'JDUPDATE':
                return { ...state, jd: action.payload, jdName: action.payload?.name }
            case 'JOBTITLEUPDATE':
                return { ...state, jobsTitle: action.payload }
            case 'RESUMESUPDATE':
                return { ...state, resumes: action.payload }
            case 'RANGEUPDATE':
                return { ...state, ranges: { ...state.ranges, [action.payload.name]: action.payload.value } }
            case 'OPTIONSCHANGE':
                return { ...state, options: { ...state.options, [action.payload.name]: action.payload.value } }
            case 'ADDCOPANYVALUE':
                return { ...state, companyValues: [...state.companyValues, action.payload] }
            case 'REMOVECOMPANYVALUE':
                return { ...state, companyValues: (() => { const pre = [...state.companyValues]; return pre.filter(item => item !== action.payload) })() }
            case 'RESET':
                return { ...initial }
        }
    }

    const [state, dispatch] = useReducer(reducer, initial);

    return <RankRequirementsContext.Provider value={{ state, dispatch }}>
        {children}
    </RankRequirementsContext.Provider>
}