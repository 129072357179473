import React, { useContext } from 'react';
import { RankRequirementsContext } from '../Contexts/RankRequirements';

const Questions_types = () => {

    const { state, dispatch } = useContext(RankRequirementsContext);
    const { options } = state;

    return (
        <div className=' my-10'>
            <h1 className=' text-xl font-semibold'>Include questions related to:</h1>
            <div className=' flex gap-6 mt-1'>
                {Object.keys(options).map((optionName, index) => (
                    <div
                        key={index}
                        className=' flex gap-1 items-end'>
                        <p>{optionName}</p>
                        <input className=' m-1' type="checkbox"
                            onChange={(e) => dispatch({ type: 'OPTIONSCHANGE', payload: { name: optionName, value: e.target.checked.toString() } })}
                        />
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Questions_types;