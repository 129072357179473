import React, { useContext, useState } from 'react';
import { RankRequirementsContext } from '../Contexts/RankRequirements';
import cross from '../Assets/close.svg'

const CompanyValues = () => {
    const { state, dispatch } = useContext(RankRequirementsContext);
    const { companyValues } = state;
    const [text, setText] = useState('');
    return (
        <div className=' p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
            <input
                className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                type='text'
                placeholder=''
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        const text = e.target.value;
                        if (text.length > 0 && companyValues.length < 5 && !companyValues.includes(text)) {
                            dispatch({ type: 'ADDCOPANYVALUE', payload: text });
                        }
                        setText("");
                    }
                }}
            />
            <div className=' flex gap-2 flex-wrap'>
                {companyValues.map((item, index) => (
                    <div key={index} className='px-5 py-2 flex gap-2 items-center rounded-full font-semibold bg-gray-100 w-fit'>
                        <span>{item}</span>
                        <img
                            className=' cursor-pointer'
                            src={cross}
                            onClick={(e) => dispatch({ type: 'REMOVECOMPANYVALUE', payload: item })}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CompanyValues;