import React, { useContext } from 'react';
import logoIcon from '../../Assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import useLogout from '../../Hooks/useLogout';

const Header = () => {
    const { isEmployerExist } = useContext(AuthContext);
    const Navigate = useNavigate();

    const [loading, error, logOutFunction] = useLogout();


    return (
        <div className='w-screen px-4 lg:px-16 py-4 flex justify-between items-center'>
            <img
                src={logoIcon}
                className=' h-10'
                onClick={()=>Navigate('/')}
            />
            {
                isEmployerExist ? (<button
                    className='px-4 py-0 rounded-md bg-primary-800 hover:bg-primary-700 text-lg text-white cursor-pointer'
                    onClick={() => logOutFunction()}
                >
                    Logout
                </button>)
                    :
                    (<button
                        className='px-4 py-0 rounded-md bg-primary-800 hover:bg-primary-700 text-lg text-white cursor-pointer'
                        onClick={() => Navigate('/auth')}
                    >
                        Login
                    </button>)
            }
        </div>
    )
}

export default Header;