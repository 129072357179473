import { createContext, useState } from "react";
import { validateAuth } from "../../Constents/functions";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isEmployerExist, setIsEmployerExist] = useState(validateAuth());

    return <AuthContext.Provider value={{ isEmployerExist, setIsEmployerExist }}>
        {children}
    </AuthContext.Provider>
}