import React, { useEffect, useRef, useState } from 'react';
import volumeIcon from "../../Assets/volume_up.svg";
import endInterview from "../../Assets/end_Interview.svg";
import skipNext from "../../Assets/skip_next.svg";
import { INTERVIEW_SUBMIT, QUESTION_SUBMIT } from '../../Constents/apis';

const Interview = ({ applicantId, questionNumber, questionData, setQuestionIndex, isInterviewCompleted, setIsInterviewCompleted, setInterviewStarted, setUploading, }) => {

    const audioLink = questionData?.audio;
    const questionText = questionData?.quest;

    const audio = useRef(new Audio(audioLink));
    function playAudio() { audio.current.play() }
    function pauseAudio() { audio.current.pause() }
    const mediaRecorder = useRef();
    const recorder = useRef({});
    const chunck = useRef([]);

    const [isPlaying, setIsPlaying] = useState(false);
    const [timeLeft, setTimeLeft] = useState(180);
    const videoElement = useRef();

    audio.current.addEventListener("pause", () => { setIsPlaying(false) });
    audio.current.addEventListener("play", () => { setIsPlaying(true) })
    const nextQuestion = async () => {
        audio.current.pause();
        recorder.current.stop();
    }
    useEffect(() => {
        (async () => {

            let stream;
            let audio;
            try {
                stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        width: { ideal: 360 },
                        height: { ideal: 180 },
                    }
                });
                audio = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        sampleRate: 44100,
                    },
                });
            } catch (err) {
                setInterviewStarted(false);
                alert("Please grant permission for the camera and microphone.");

            }



            if (stream && audio) {
                const mixedStream = new MediaStream([...stream.getTracks(), ...audio.getTracks()]);
                recorder.current = new MediaRecorder(mixedStream);
                recorder.current.ondataavailable = (e) => {
                    chunck.current.push(e.data)
                };
                recorder.current.onstop = async () => {
                    setUploading(true);
                    const form = new FormData();
                    const blob = new Blob(chunck.current, { 'type': 'video/mp4' });
                    chunck.current = [];

                    form.append('file', blob);
                    form.append('id', applicantId);
                    form.append('question_no', questionNumber - 1);

                    fetch(QUESTION_SUBMIT, {
                        method: 'POST',
                        body: form
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log('Chunk uploaded successfully:', data);
                            setQuestionIndex(pre => pre + 1);
                            setUploading(false);
                        })
                        .catch(error => {
                            setUploading(false);
                            console.error('Error uploading chunk:', error);
                        });

                    stream.getTracks().forEach((track) => track.stop());
                    audio.getTracks().forEach((track) => track.stop());

                };
                recorder.current.start(1000);
            } else {
                console.warn('No stream available.');
            }
        })();
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setTimeLeft(
                (pre) => {
                    if (pre === 0) {
                        nextQuestion();
                    }
                    else {
                        return pre - 1;
                    }
                }
            )
        }, 1000);
    }, [])


    useEffect(() => {
        (() => !isPlaying && audio.current.play())();
        return () => isPlaying && audio.current.pause();
    }, []);
    return (
        <div className=' w-full'>
            {!isInterviewCompleted && (<>
                <div className=' w-full flex justify-end'>
                    <span className=' text-lg font-semibold'>Time Left: {timeLeft}</span>
                </div>
                <div className='mt-6 flex-1 p-4 lg:p-10 rounded-3xl bg-gray-100'>
                    <h4 className=' text-xl font-bold'>Question: {questionNumber}</h4>
                    <p className=' text-lg mt-3'>{questionText}</p>
                    <div className=' mt-4 flex gap-2 items-center'>
                        {/* <h4 className=' text-xl font-bold'>Time Left: 00:00:60</h4> */}
                        <img
                            className=' h-6'
                            title='volumeIcon'
                            // src={isPlaying ? pauseIcon : playIcon}
                            src={volumeIcon}
                            // onClick={() => audio.play()}
                            // onClick={isPlaying ? () => { setIsPlaying(false); audio.pause(); } : () => { setIsPlaying(true); audio.play() }}
                            onClick={() => !isPlaying && audio.current.play()}
                        />
                    </div>
                </div>
                <div className=' flex justify-center mt-8'>
                    <div className=' flex gap-6 lg:gap-12 p-4 lg:p-10 rounded-3xl bg-gray-100 w-fit'>
                        <div className=' flex flex-col justify-center w-fit gap-6'>
                            <h4 className=' text-2xl font-semibold'>End Interview</h4>
                            <img
                                className=' h-12'
                                title='endInterview'
                                src={endInterview}
                                onClick={() => {
                                    // interview completed api call for db updation : anuj
                                    const interviewend = async () => {
                                        try {
                                            const res = await fetch(INTERVIEW_SUBMIT, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify(
                                                    {
                                                        id: applicantId,
                                                        // ques_no: questionIndex,
                                                    })
                                                });
                                                console.log("inside interview submit")
                                            if (!res.ok) {
                                                throw new Error("somthing went wrong");
                                            }
                                            // else {
                                            //     const question = await res.json();
                                            //     setQuestionData(question);
                                            // }

                                        }
                                        catch (error) {
                                            console.log("interview ending api error : ", error);
                                        }

                                    }
                                    interviewend();
                                    nextQuestion(chunck.current)
                                    setIsInterviewCompleted(true);
                                    setQuestionIndex(pre => pre + 1);
                                    pauseAudio();
                                }}
                            />
                        </div>
                        <div className=' flex flex-col justify-center w-fit gap-6'>
                            <h4 className=' text-2xl font-semibold'>Next Question</h4>
                            <img
                                onClick={() => nextQuestion(chunck.current)}
                                className=' h-12 text-blue-800'
                                title='skipNext'
                                src={skipNext}
                            />
                        </div>
                    </div>
                </div>
            </>)}
        </div>
    )
}

export default Interview;