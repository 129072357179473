import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { hrLoginSchema } from '../../Constents/schema';
import { HR_LOGIN } from '../../Constents/apis';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const LogIn = ({ setIsFirstTimeUser }) => {
    const Navigate = useNavigate();
    const { setIsEmployerExist } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const initialEnteries = {
        hrEmail: '',
        password: '',
    }

    const hrRegisterFunction = async (params = {}) => {

        try {
            setLoading(true);
            const res = await fetch(HR_LOGIN, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: params.hrEmail,
                    password: params.password,
                })
            });
            if (res.ok) {
                const data = await res.json();
                window.sessionStorage.setItem("accessToken", data?.token);
                window.sessionStorage.setItem("email", params.hrEmail);
                window.sessionStorage.setItem("addedTime", Date.now());
                setIsEmployerExist(true);
                Navigate('/');
            }
            else {
                const err = await res.json();
                throw new Error(err.error);
            }
        }
        catch (err) {
            setError(err.message);
        }
        finally {
            setLoading(false);
        }
    }


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            validationSchema: hrLoginSchema,
            initialValues: initialEnteries,
            onSubmit: (values, action) => {
                hrRegisterFunction(values);
                // acCreater(values).then(action.resetForm());
            },
        });


    return (
        <form
            onSubmit={handleSubmit}
            className='p-4 rounded-xl min-w-80 md:min-w-96'
            style={{ "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>*Email </p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.hrEmail && touched.hrEmail ? errors.hrEmail : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='text'
                    id='hrEmail'
                    name='hrEmail'
                    placeholder='Enter your email here....'
                    value={values.hrEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div>
                <div className='mb-1 flex gap-2 items-end'>
                    <p className=' font-semibold'>Password</p>
                    <p className='text-sm text-[#ff0000]'>
                        {errors.password && touched.password ? errors.password : " "}
                    </p>
                </div>
                <input
                    className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                    type='password'
                    id='password'
                    name='password'
                    placeholder='create your password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <p className='mb-2 text-[#ff0000]'> {error}</p>
            <button
                className=' px-4 py-2 rounded-lg text-white bg-primary-800 hover:bg-primary-500 hover:cursor-pointer'
            >
                LogIn
            </button>

            <p className='mt-8 text-xs font-semibold text-gray-900'>New user
                <span
                    className='ml-1 text-primary-800 font-bold cursor-pointer hover:underline'
                    onClick={() => setIsFirstTimeUser(true)}
                >sign in </span>here
            </p>
        </form>
    )
}

export default LogIn;