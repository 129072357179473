import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Employer from './Pages/Employer';
import Applicant from './Pages/Applicant';
import HomePage from './Pages/HomePage';
import JD_Page from './Pages/JD_Page';
import Interview from './Pages/Interview';
import Ranking from './Pages/Ranking';
import Auth from './Components/Auth/Auth';
import Protection from './Components/Auth/Protection';
import HrProfile from './Pages/HrProfile';
import { AuthProvider } from './Components/Auth/AuthContext';
import { RankRequirementsProvider } from './Contexts/RankRequirements';

const appRoutes = createBrowserRouter([{
    path: "/",
    element: <App />,
    children: [
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/interview",
            element: <Interview />,
        },
        {
            path: "/applicant",
            element: <Applicant />
        },
        {
            path: "/auth",
            element: <Auth />
        },
        {
            path: "/employer",
            element: (<Protection>
                <RankRequirementsProvider>
                    <Employer />
                </RankRequirementsProvider>
            </Protection>),
        },
        {
            path: "/ranking",
            element: <Protection><Ranking /></Protection>
        },
        {
            path: "/hr-profile",
            element: <Protection><HrProfile /></Protection>
        },
    ]
}])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <RouterProvider router={appRoutes} />
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
