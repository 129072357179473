import React, { useState } from 'react';
import Introduction from '../Components/Interview/Introduction';
import InterviewSection from '../Components/Interview/InterviewSection';

const InterviewPage = () => {
    const [isEnglishOk, setIsEnglishOk] = useState("Y");
    const [isInterviewStarted, setInterviewStarted] = useState(false);

    return (
        <div className='overflow-auto overflow-x-hidden'>
            {isInterviewStarted ? (<InterviewSection setInterviewStarted={setInterviewStarted}/>)
                :
                (<Introduction
                    isEnglishOk={isEnglishOk}
                    setIsEnglishOk={setIsEnglishOk}
                    isInterviewStarted={isInterviewStarted}
                    setInterviewStarted={setInterviewStarted}
                />)}

        </div>
    )
}

export default InterviewPage;