import React, { useState } from 'react';
import { JobsType } from '../Constents/text';

const JD_Page = ({ setSelectedJobsTitle, form }) => {

    const [department, setDepartment] = useState('');
    const [JD_Name, set_JD_Name] = useState('');

    return (
        <div className=' flex flex-col mt-12'>
            {/*  */}
            <form action=""
                onChange={(e) => { set_JD_Name(e.target.files[0]?.name); form.current.append("jd", e.target.files[0]) }}>
                <input type="file" multiple="multiple" id='talent_JD_upload_label' className=' hidden' />
                <label htmlFor='talent_JD_upload_label' className=' px-6 py-2 rounded-full bg-primary-800 hover:bg-primary-700 text-lg text-white cursor-pointer'>
                    Select JD
                </label>
                {JD_Name && (<h6 className=' mt-2 text-sm text-blue-400'>{JD_Name}</h6>)}
            </form>
            {/*  */}
            <div className=' max-w-[24rem] mt-4'>

                <h6 className=' mb-2 ml-2 text-sm'>*Select job industry</h6>
                <div className=' w-full p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
                    <select
                        onChange={(e) => setDepartment(e.target.value)}
                        className=' outline-none border-none accent-primary-600'
                    >
                        {JobsType.map((item, index) => (
                            <option
                                key={index}
                                value={item.department}
                                className='hover:bg-primary-200'
                            >
                                {item.department}
                            </option>))
                        }
                    </select>
                </div>
                {
                    department && (<>
                        <h6 className=' mb-2 ml-2 text-sm'>*Select job title</h6>
                        <div className=' w-full p-2 mb-4 mt-2 rounded-lg border-[1px] border-gray-200 flex flex-col gap-2'>
                            <select className=' outline-none border-none'
                                onChange={(e) => setSelectedJobsTitle(pre => e.target.value)}
                            >
                                {JobsType?.filter(item => item.department === department)[0]?.jobs?.map((item, index) => (
                                    <option
                                        key={index}
                                        value={item}
                                    >
                                        {item}
                                    </option>))
                                }
                            </select>
                        </div>
                    </>)}
            </div>
        </div>
    )
}

export default JD_Page;