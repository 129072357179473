import React, { useState } from 'react';
import arrow from '../Assets/arrow_up.svg';
import { Hr } from '../Constents/ConstentComp';
import { months, years } from '../Constents/text';

const transitionStyle = {
    transitionDuration: "400ms",
}

const WorkExperience = () => {
    const [education, setEducation] = useState(new Array(1).fill({}))
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='flex flex-col'>
            <div className='flex w-full justify-between py-2'>
                <h2 className='text-2xl font-semibold'>Work Exeprience</h2>
                <img
                    className={` cursor-pointer ${isOpen ? " rotate-0" : " rotate-180"}`}
                    style={transitionStyle}
                    src={arrow}
                    onClick={() => setIsOpen(pre => !pre)}
                />
            </div>
            <div
                className={`gap-4 transition-all ease-in-out rounded-lg grid ${isOpen
                    ? "grid-rows-[1fr] "
                    : "grid-rows-[0fr] "
                    }`}
                style={transitionStyle}
            >

                <div className='overflow-hidden flex flex-col gap-4'>
                    <p className=' font-semibold'>
                        Add your work experience in descending order
                    </p>
                    <div className=' flex flex-col gap-8'>

                        {education?.map((item, index) => (
                            <div
                                key={index}
                                className='py-2 px-3 rounded-lg border-[1px] border-gray-400'>
                                <div className=' flex flex-col gap-1'>
                                    <label className=' font-semibold'>
                                        Copany Name
                                    </label>
                                    <input
                                        className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                                        type='text'
                                        placeholder=''
                                    />
                                </div>
                                <div className=' flex flex-col gap-1'>
                                    <label className=' font-semibold'>
                                        Designation
                                    </label>
                                    <input
                                        className=' py-2 px-3 outline-none border-[1px] border-gray-400 rounded-sm'
                                        type='text'
                                        placeholder=''
                                    />
                                </div>
                                <div className=' flex flex-row gap-2'>
                                    <div className=' flex flex-1 flex-col gap-1'>
                                        <label className=' font-semibold'>
                                            Starting Month
                                        </label>
                                        <select name="highestQualification" id="highestQualification" className=' border-[1px] border-gray-400 rounded-sm outline-none px-1 py-2 w-full' >
                                            {months.map((item) => (<option value={item} key={item}>{item}</option>))}
                                        </select >
                                    </div>
                                    <div className=' flex flex-1 flex-col gap-1'>
                                        <label className=' font-semibold'>
                                            Starting Year
                                        </label>
                                        <select name="highestQualification" id="highestQualification" className=' border-[1px] border-gray-400 rounded-sm outline-none px-1 py-2 w-full' >
                                            {years.map((item) => (<option value={item} key={item}>{item}</option>))}
                                        </select >

                                    </div>
                                </div>
                                <div className=' flex flex-row gap-2'>
                                    <div className=' flex flex-1 flex-col gap-1'>
                                        <label className=' font-semibold'>
                                            Ending Month
                                        </label>
                                        <select name="highestQualification" id="highestQualification" className=' border-[1px] border-gray-400 rounded-sm outline-none px-1 py-2 w-full' >
                                            {months.map((item) => (<option value={item} key={item}>{item}</option>))}
                                        </select >
                                    </div>
                                    <div className=' flex flex-1 flex-col gap-1'>
                                        <label className=' font-semibold'>
                                            Ending Year
                                        </label>
                                        <select name="highestQualification" id="highestQualification" className=' border-[1px] border-gray-400 rounded-sm outline-none px-1 py-2 w-full' >
                                            {years.map((item) => (<option value={item} key={item}>{item}</option>))}
                                        </select >
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className=' flex justify-end'>
                            <button
                                onClick={() => setEducation(pre => [...pre, {}])}
                                className=' px-4 py-2 hover:bg-primary-500 border-[1px] border-black rounded-lg'>Add more experience</button>
                        </div>
                        <div className=' h-1'></div>
                    </div>
                </div>
            </div>
            <Hr />
        </div>
    )
}

export default WorkExperience;


