import React, { useState } from 'react'

const Email = ({ email, setEmail }) => {

    const [isEmailCorrect, setIsEmailCorrect] = useState(false);
    return (
        <div>
            <div className='mt-6 mb-2 flex gap-2 items-end'>
                <p className=' font-semibold'>*Email </p>
                {!isEmailCorrect && email.length > 0 && (<p className=' text-sm text-red-700 font-semibold'>Please enter a valid email address </p>)}
            </div>
            <input
                className=' py-1 mb-4 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
                type='text'
                placeholder='Enter your email here....'
                value={email}
                onChange={(e) => {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    setEmail(e.target.value);
                    setIsEmailCorrect(emailRegex.test(e.target.value));
                }}
            />
        </div>
    )
}
export default Email;