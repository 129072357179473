import React from 'react';
import { MainLogoComponent } from '../Components/Tiny';

const HrProfile = () => {
    return (
        <div className=''>
            <div>
                
            </div>
        </div>
    )
}

export default HrProfile;