import React, { useState } from 'react';
import arrow from '../Assets/arrow_up.svg';
import { Hr } from '../Constents/ConstentComp';

const transitionStyle = {
    transitionDuration: "400ms",
}


const Skills = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [primarySkilles, setPrimarySkilles] = useState([]);
    const [primarySkilleInput, setPrimarySkilleInput] = useState("");
    const [SecondarySkilles, setSecondarySkilles] = useState([]);
    const [SecondarySkilleInput, setSecondarySkilleInput] = useState("");
    return (
        <div className='flex flex-col'>
            <div className='flex w-full justify-between py-2'>
                <h2 className='text-2xl font-semibold'>Skill Details</h2>
                <img
                    className={` cursor-pointer ${isOpen ? " rotate-0" : " rotate-180"}`}
                    style={transitionStyle}
                    src={arrow}
                    onClick={() => setIsOpen(pre => !pre)}
                />
            </div>
            <div
                className={`gap-4 transition-all ease-in-out rounded-lg grid ${isOpen
                    ? "grid-rows-[1fr] "
                    : "grid-rows-[0fr] "
                    }`}
                style={transitionStyle}
            >
                <div className=' overflow-hidden flex flex-col gap-4'>
                    <div className=' border-[1px] border-gray-400 px-2 py-4 rounded-lg flex flex-col gap-2'>
                        <label htmlFor="Primary skills">Primary skills</label>
                        <input
                            className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400'
                            type='text'
                            placeholder=''
                            value={primarySkilleInput}
                            onChange={(e) => setPrimarySkilleInput(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    const text = e.target.value;
                                    if (text.length > 0 && primarySkilles.length < 10 && !primarySkilles.includes(text)) {
                                        setPrimarySkilles(pre => [...pre, e.target.value]);
                                    }
                                    setPrimarySkilleInput("");
                                }
                            }}
                        />
                        <div className=' flex gap-2 flex-wrap'>
                            {primarySkilles.map((item, index) => (<span key={index} className='px-5 py-2 rounded-full font-semibold bg-gray-100 w-fit'>{item}</span>))}
                        </div>
                    </div>
                    <div className=' border-[1px] border-gray-400 px-2 py-4 rounded-lg flex flex-col gap-2'>
                        <label htmlFor="Primary skills">Secondary skills</label>
                        <input
                            className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400'
                            type='text'
                            placeholder=''
                            value={SecondarySkilleInput}
                            onChange={(e) => setSecondarySkilleInput(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    const text = e.target.value;
                                    if (text.length > 0 && SecondarySkilles.length < 10 && !SecondarySkilles.includes(text)) {
                                        setSecondarySkilles(pre => [...pre, e.target.value]);
                                    }
                                    setSecondarySkilleInput("");
                                }
                            }}
                        />
                        <div className=' flex gap-2 flex-wrap'>
                            {SecondarySkilles.map((item, index) => (<span key={index} className='px-5 py-2 rounded-full font-semibold bg-gray-100 w-fit'>{item}</span>))}
                        </div>
                    </div>
                    <div className=' w-full h-1'>
                    </div>
                </div>
            </div>
            <Hr />
        </div>
    )
}

export default Skills