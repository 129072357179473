import React from 'react';
import Info_Icon from '../Assets/Components/Info_Icon';

const Heading_with_title = ({ text, title }) => {
    return (
        <div className=' flex gap-2 items-end'>
            <span className=' text-3xl leading-7 font-semibold'>{text}</span>
            <Info_Icon/>
        </div>

    )
}

export default Heading_with_title