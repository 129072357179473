import React, { useContext } from 'react';
import Heading_with_title from '../Components/Heading_with_title';
import Questions_types from '../Components/Questions_types';
import Ranges_Section from '../Components/Ranges_Section';
import useEmployerDataUploader from '../Hooks/useEmployerDataUploader';
import { RankRequirementsContext } from '../Contexts/RankRequirements';
import Email from '../Components/Email';
import Specific_Questions from '../Components/Specific_Questions';
import Input from '../Components/Input';
import JdSlectButton from '../Components/JdSlectButton';
import JobTitle from '../Components/JobTitle';
import ResumesUploadingButton from '../Components/ResumesUploadingButton';
import CompanyValues from '../Components/CompanyValues';

const Employer = () => {
    const { state, dispatch } = useContext(RankRequirementsContext);
    const [loading, infoPostingError, submitInfo] = useEmployerDataUploader();
    return (
        <div className=''>
            <div className=' flex flex-row gap-16'>
                <div className=' flex-1'>
                    <JdSlectButton />
                    {state.jdName && (<h6 className=' mt-2 text-sm text-blue-400'>{state.jdName}</h6>)}
                    <JobTitle />
                    <ResumesUploadingButton />
                    <Ranges_Section />
                    <Input
                        type={'text'}
                        heading={'Company Name'}
                        placeholder={''}
                        value={state.companyName}
                        setValue={(value) => { dispatch({ type: 'COMPANYNAME', payload: value }) }}
                    />
                    <Questions_types />
                    <Heading_with_title text={"values"} title={"Insert company values saprated by comma"} />
                    <CompanyValues />
                </div>
                <div className=' flex-1 h-1'>
                    <Email email={state.email} setEmail={(value) => { dispatch({ type: 'EMAIL', payload: value }) }} />
                    <Specific_Questions />

                    {loading ?
                        (<p>loading....</p>)
                        : (
                            <button
                                className='mt-4 px-4 py-2 rounded-full bg-primary-800 text-lg font-semibold text-white cursor-pointer'
                                onClick={() => submitInfo()}
                            >
                                Submit
                            </button>
                        )}

                </div>
            </div>
        </div>
    );
}

export default Employer;