import * as yup from 'yup';

export const hrRegisterSchema = yup.object({
    hrName: yup.string().max(100).required("Name is required"),
    companyName: yup.string().max(100).required("company name is required"),
    hrEmail: yup.string().email().required("Email is required"),
    password: yup.string().min(2, "password is too short").required(" Enter your password"),
    confirmPassword: yup.string().required("").oneOf([yup.ref('password'), null], "password should match"),
});
export const hrLoginSchema = yup.object({
    hrEmail: yup.string().email().required("Email is required"),
    password: yup.string().min(2, "password is too short").required(" Enter your password"),
});