import React from 'react';

const Input_Range = ({ text, setter, ranges, setTotleNumbers, totleNumbers }) => {

    return (
        <div className=" flex gap-4">
            <p className=' w-28 text-left'>{text}</p>
            <input
                type='range'
                onChange={(e) => setter(pre => { return { ...pre, [text]: e.target.value } })}
                value={ranges[text]}
                className=' accent-primary-700'
            />
            <span>{ranges[text]}</span>
        </div>

    )
}

export default Input_Range;