import React from 'react'

const Introduction = ({ isEnglishOk, setIsEnglishOk, isInterviewStarted, setInterviewStarted }) => {
    return (
        (<div className=' flex flex-col lg:flex-row gap-12 mt-12'>
            <div className=' flex-1 p-10 rounded-3xl bg-gray-100'>
                <h4 className=' text-xl font-bold'>Please note the following</h4>
                <ul className=' mt-4 list-disc ml-4'>
                    <li className=' text-lg'>Use the <span className=' font-bold'>"Next Question"</span> button to bypass a question.</li>
                    <li className=' text-lg'>Use the <span className=' font-bold'>"End Interview"</span> button to exit the interview at any time.</li>
                    <li className=' text-lg'>Make sure your microphone and camera are functioning correctly.</li>
                    <li className=' text-lg'>Each question has a set time limit and will automatically proceed.</li>
                    <li className=' text-lg'>Ensure you are in a quiet environment with minimal distractions.</li>
                    <li className=' text-lg'>Stay focused; the interview will flow automatically.</li>
                    <li className=' text-lg'>Maintain eye contact with the camera for better assessment.</li>
                </ul>
            </div>
            <div className='flex-1'>
                <div className=' flex gap-4'>
                    <h2 className='  lg:text-xl  font-bold'>Are you comfortable in English Language?</h2>
                    <div className='px-2 py-1 rounded-3xl bg-gray-100'>
                        <select
                            onChange={(e) => setIsEnglishOk(e.target.value)}
                            className=' outline-none bg-transparent'
                        >
                            {["Y", "N"].map((item) => (
                                <option
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </option>))
                            }
                        </select>
                    </div>

                </div>
                <button
                    className=' mt-6 px-6 py-2 rounded-full bg-primary-800 text-lg text-white cursor-pointer'
                    onClick={() => isEnglishOk === "Y" && setInterviewStarted(true)}
                >
                    Start Interview
                </button>
            </div>
        </div>)
    )
}

export default Introduction;