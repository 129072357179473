export const NextQuestionComponent = () => (
    <div className=' flex items-center justify-center w-full h-32'>
        <h1 className=' text-2xl font-semibold'>Your Answer is submiting....</h1>
    </div>
)

export const InterviewCompleted = () => (
    <div className=' flex items-center justify-center w-full h-32'>
        <h1 className=' text-2xl font-semibold'>Your Interview is completed...</h1>
    </div>
)
export const QuestionLoading = () => (
    <div className=' flex items-center justify-center w-full h-32'>
        <h1 className=' text-2xl font-semibold'>Question Loading...</h1>
    </div>
)
export const InterviewQuestionError = () => (
    <div className=' flex items-center justify-center w-full h-32'>
        <h1 className=' text-2xl font-semibold'>Error while getting question. Make sure your url is correct</h1>
    </div>
)

export const MainLogoComponent = () => (<h1 className=' text-5xl font-bold'>Talent <span className=' text-blue-700'>Ai</span></h1>);