import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from './AuthContext'

const Protection = ({ children }) => {

    const { isEmployerExist } = useContext(AuthContext);
    if (isEmployerExist) {
        return children;
    }
    else {
        window.history.replaceState({}, '', '/auth');
        window.location.reload();
    }
}
export default Protection;