import React, { useContext, useState } from 'react';
import { EMPLOYER_REQUIREMENTS_POSTER } from '../Constents/apis';
import { RankRequirementsContext } from '../Contexts/RankRequirements';


const useEmployerDataUploader = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { state, dispatch } = useContext(RankRequirementsContext);

    const uploder = async () => {
        const form = new FormData();

        for (let file of state.resumes) {
            form.append("files", file);
        }

        form.append('jd', state.jd);
        const questions = [...state.questions]
        questions.flat(1).forEach((item) => { form.append('SpecQues', item) });

        form.append('disc', state.options?.["DISC Profile"]);
        form.append('situation', state.options?.["Situation Based"]);
        form.append('multiple_value', state.companyValues);
        form.append('job_title', state.jobsTitle);
        form.append('company', state.companyName);
        form.append('email', state.email);

        for (const range in state.ranges) {
            form.append(range?.toLocaleLowerCase(), state.ranges?.[range]);
        }

        try {
            setLoading(true);
            setError("");
            const res = await fetch(EMPLOYER_REQUIREMENTS_POSTER, {
                "method": "POST",
                body: form,
            });
            if (!res.ok) {
                const msg = await res.json();
                setError(msg?.error);
            }
            else {
            }
        } catch (err) {
            setError(`Something went wrong /only ".zip"  files are allowed`);
        }
        finally {
            (() => window.location.reload())();
            dispatch({ type: 'RESET' });
            setLoading(false);
        }
        return "";
    }

    return [loading, error, uploder];
}
export default useEmployerDataUploader;