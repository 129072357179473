import React, { useEffect, useState } from 'react'
import { QUESTION_GETTER, INTERVIEW_SUBMIT } from '../../Constents/apis';
import Interview from './Interview';
import { InterviewCompleted, InterviewQuestionError, NextQuestionComponent, QuestionLoading } from '../Tiny';

const InterviewSection = ({ setInterviewStarted }) => {
    const searchParam = new URLSearchParams(window.location.search);
    const applicantId = searchParam.get('q');

    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionLoadingState, setQuestionLoading] = useState(false);
    const [error, setError] = useState('');
    const [questionData, setQuestionData] = useState({});
    const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        const questionGetter = async () => {
            // is response code is 204 the Interview is over;
            if (!isInterviewCompleted) {
                try {
                    setQuestionLoading(true);
                    const res = await fetch(QUESTION_GETTER, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(
                            {
                                id: applicantId,
                                ques_no: questionIndex,
                            })
                    });

                    if (res.status === 204) {
                        setIsInterviewCompleted(true);
                        setQuestionLoading(false);
                        return <InterviewCompleted />
                    }
                    if (!res.ok) {
                        const err = await res.json();
                        throw new Error(err.error);
                    }
                    const question = await res.json();
                    setQuestionData(question);
                }
                catch (error) {
                    setError(error.message);
                }
                finally {
                    setQuestionLoading(false);
                }
            }
        };
        questionGetter();
    }, [questionIndex]);



    if (questionLoadingState) { return (<QuestionLoading />) }
    else if (error) {
        return (<InterviewQuestionError />)
    }
    else if (!questionLoadingState && !isInterviewCompleted) {
        return ((<Interview
            setInterviewStarted={setInterviewStarted}
            applicantId={applicantId}
            questionNumber={questionIndex + 1}
            questionData={questionData}
            setQuestionIndex={setQuestionIndex}
            isInterviewCompleted={isInterviewCompleted}
            setIsInterviewCompleted={setIsInterviewCompleted}
            setUploading={setUploading}
        />))
    }
    else if (uploading) { return (<NextQuestionComponent />) }
    else if (isInterviewCompleted) { return (<InterviewCompleted />) }
}

export default InterviewSection;