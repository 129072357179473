import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
function App() {

  return (
    <div className=' w-screen overflow-x-hidden'>
      <Header />
      <div className='px-4 lg:px-16 py-4'>
        <Outlet />
      </div>
    </div>
  )
}

export default App;
